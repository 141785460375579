<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form v-else
          novalidate
          @submit.prevent="onSubmit"

        >
          <b-row>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.code')"
                vid="code"
              >
                <b-form-group
                  :label="$t('labels.code')"
                >
                  <b-form-input
                    v-model="form.code"
                    :placeholder="$t('labels.code')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="country"
                :name="$t('country')"
              >
                <b-form-group
                  :label="$t('country')"
                >
                  <v-select
                    v-model="form.country_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="countries"
                    :reduce="item => item.id"
                    :placeholder="$t('country')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

<!--            <b-col-->
<!--              md="6"-->
<!--              cols="12"-->
<!--            >-->
<!--              <validation-provider-->
<!--                v-slot="{ errors }"-->
<!--                vid="stadium_id"-->
<!--                :name="$t('labels.stadium')"-->

<!--              >-->
<!--                <b-form-group-->
<!--                  :label="$t('labels.stadium')"-->
<!--                >-->
<!--                  <v-select-->
<!--                    v-model="form.stadium_id"-->
<!--                    :dir="$store.state.appConfig.layout.direction"-->
<!--                    label="title_label"-->
<!--                    :options="stadiums"-->
<!--                    :reduce="item => item.id"-->
<!--                    :placeholder="$t('labels.stadium')"-->
<!--                  />-->
<!--                  <small class="text-danger">{{ errors[0] }}</small>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="team_type"
                :name="$t('labels.team_type')"
              >
                <b-form-group
                  :label="$t('labels.team_type')"
                >
                  <v-select
                    v-model="form.team_type"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="team_types"
                    :reduce="item => item.value"
                    :placeholder="$t('labels.team_type')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="form.team_type === 'club'"
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="league_id"
                :name="$t('labels.select_league')"
              >
                <b-form-group
                  :label="$t('labels.select_league')"
                >
                  <v-select
                    v-model="form.league_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="title_label"
                    :options="leagues"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.select_league')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="6"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.establishing_date')"
                vid="establishing_date"
              >
                <b-form-group>
                  <DatePicker
                    v-model="form.establishing_date"
                    :label="$t('labels.establishing_date')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                vid="points-input"
                :name="$t('labels.weight')"
                rules="required|integer"
              >
                <b-form-group
                  label="weight"
                  label-for="weight-input"
                >
                  <b-form-input
                    id="weight-input"
                    v-model="form.weight"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.order')"
                vid="order"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.order')"
                >
                  <b-form-input
                    v-model="form.order"
                    :placeholder="$t('labels.order')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="URL"
                vid="video_url"
              >
                <b-form-group
                  :label="$t('labels.youtube_url')"
                >
                  <b-form-input
                    v-model="form.video_url"
                    :placeholder="$t('labels.youtube_url')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"
              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <b-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="logo"
                :name="$t('labels.logo')"
              >
                <ImagePreview
                  v-model="logo"
                  :label="$t('labels.logo')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      image: null,
      logo: null,
      countries: [],
      stadiums: [],
      team_types: [],
      leagues: [],
      form: {
        name: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        league_id: null,
        code: null,
        image: null,
        logo: null,
        video_url: null,
        establishing_date: null,
        country_id: null,
        stadium_id: null,
        team_type: null,
        order: null,
        weight:3,
        status: 'active',
      },
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
    logo(val) {
      if (val) {
        this.form.logo = val
      } else {
        this.form.logo = 'delete'
      }
    },
  },
  created() {
    this.getCountries()
    this.getStadiums()
    this.getTeamTypes()
    this.getLeagues()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getLeagues() {
      this.axios.get('/lists/League')
        .then(res => {
          this.leagues = res.data
        })
    },
    getCountries() {
      this.axios.get('/lists/Country')
        .then(res => {
          this.countries = res.data
        })
    },
    getStadiums() {
      this.axios.get('/lists/Stadium')
        .then(res => {
          this.stadiums = res.data
        })
    },
    getTeamTypes() {
      this.axios.get('/lists/teams/types')
        .then(res => {
          this.team_types = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            code: data.code,
            country_id: data.country_id,
            establishing_date: data.establishing_date,
            stadium_id: data.stadium_id,
            team_type: data.team_type,
            league_id: data.league_id,
            description: data.description,
            status: data.status,
            video_url: data.video_url,
            order: data.order,
            weight:data.weight,


          }
          // this.form.description = {
          //   ar: null,
          //   en: null,
          // }
          this.image = data.image
          this.logo = data.logo
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });

    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      if (!($form.logo instanceof File)) {
        delete $form.logo
      }
      return $form
    },
  },

}
</script>
